import httpManager from "../axios/httpManager";

export function getHonorCasinoBetlist(search, pageNum, pageSize) {
    let url = '/casino/honor/bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function gettHonorCasinoAccountBalance(userId) {
    return httpManager.get('/casino/honor/get_account_balance?userId=' + userId)
}

export function subjecttHonorCasinoMemberCasinoCash(userId) {
    return httpManager.get('/casino/honor/subject_member_casino_cash?userId=' + userId)
}
export function sysData(time) {
    return httpManager.get('/casino/honor/syns_data?time=' + time)
}
