import httpManager from "../axios/httpManager";


export function getRankList (rank, pageNum, pageSize) {
    return httpManager.post('/rank/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, rank);
}

export function getRankById (id) {
    return httpManager.get('/rank/get_by_id?id=' + id);
}

export function updateRank (rank) {
    return httpManager.post('/rank/update', rank);
}

export function copyRank (id) {
    return httpManager.get('/rank/copy?id=' + id);
}

export function deleteRank (id) {
    return httpManager.get('/rank/delete?id=' + id);
}
export function updateLesureBetConfig (config) {
    return httpManager.post('/rank/update_leisure_bet_config', config);
}