import httpManager from "../axios/httpManager";

export function getCustomerCetnerList (search, pageNum, pageSize) {
    return httpManager.post('/customercenter/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, search);
}
export function updateCustomerCetner (cc) {
    return httpManager.post('/customercenter/update', cc);
}

export function getCustomerCetnerById (id) {
    return httpManager.get('/customercenter/get_by_id?id=' + id);
}
export function displayNoneAll (record){
    return httpManager.post('/customercenter/display_none_all',record);
}