import httpManager from "../axios/httpManager";

export function getGroupList (entity, pageNum, pageSize) {
    return httpManager.post('/usergroup/user_group_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, entity);
}

export function getGroupById (id) {
    return httpManager.get('/usergroup/get_by_id?id=' + id)
}
export function updateGroup(userGroup) {
    return httpManager.post('/usergroup/update', userGroup);
}

export function copyGroup (id) {
    return httpManager.get('/usergroup/copy_group?id=' + id);
}

export function deleteGroup (id) {
    return httpManager.get('/usergroup/delete_group?id=' + id);
}
