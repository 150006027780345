import httpManager from "../axios/httpManager";


export function getUserList(search, pageNum, pageSize, orderBy) {
    let url = '/user/user_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize + '&orderBy=' + orderBy;
    return httpManager.post(url, search);
}

export function saveUser(user) {

    return httpManager.post('/user/save', user);
}

export function getUserById(id) {
    return httpManager.get('/user/get_by_id?id=' + id)
}

export function updateUser(user) {
    return httpManager.post('/user/update', user);
}

export function updateUserCashAndPoint(user) {
    return httpManager.post('/user/update', user);
}

//회원상제정보용 메소드
export function getUserStatisticById(userId) {
    return httpManager.get('/user/user_statistic_by_id?userId=' + userId);
}

export function partnerInfoForUserPage(nickname) {
    return httpManager.post('/user/select_partner_info_for_user?nickName=' + nickname);
}

export function destoryUserSession(username) {
    return httpManager.get('/user/destory_usersession?username=' + username);
}

export function getCoinByUserId(userId) {
    return httpManager.get('/user/get_coin_amount?userId=' + userId)
}
