let baseManagerUrl = '';
let webSokcetUrl = '';

function getProductionUrl() {
    let prot = window.location.protocol
    let domain = window.location.host
    return prot + '//' + domain
}

function getProductWebsocketUrl() {
    let domainOrigin = window.location.host
    return 'ws://' + domainOrigin + '/ws/yewresult-0.0.1-SNAPSHOT/sportswebsocket'
}

switch (process.env.NODE_ENV) {
    case 'development':
        baseManagerUrl = 'http://localhost:7070/manager/'
        webSokcetUrl = 'ws://rod-777.com/ws/yewresult-0.0.1-SNAPSHOT/sportswebsocket'
        break
    case 'production':

        baseManagerUrl = getProductionUrl() + '/manager/'
        webSokcetUrl = getProductWebsocketUrl()
        break
}

export const BASE_MANAGER_URL = baseManagerUrl
export const WEBSOCKET_URL = webSokcetUrl
export const TIMEOUT = 20000