import httpManager from "../axios/httpManager";

export function getPartnerList(search, pageNum, pageSize) {
    let url = '/partner/partner_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function savePartner  (partner) {
    return httpManager.post('/partner/save', partner);
}
export function getPartnerById  (id) {
    return httpManager.get('/partner/get_by_id?id=' + id)
}
export function updatePartner  (partner) {
    return httpManager.post('/partner/update', partner);
}


export function getPartnerLogLogin  (patrnerId) {
    return httpManager.get('/partner/get_log_login?partnerId=' + patrnerId);
}
export function partnerUserRechargeList  (partnerId, search) {
    return httpManager.post('/partner/partner_user_recharge_list?partnerId=' + partnerId, search)
}
export function partnerUserExchangeList  (partnerId, search) {
    return httpManager.post('/partner/partner_user_exchange_list?partnerId=' + partnerId, search)
}


export function delAgent  (id) {
    return httpManager.get('/partner/del_agent?id=' + id);
}
export function delGeneralAgent  (id) {
    return httpManager.get('/partner/del_general_agent?id=' + id);
}