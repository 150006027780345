import httpManager from "../axios/httpManager";

export function sendMessageFromGame(message) {
    return httpManager.post('/message/sendGameMessage', message)
}
export function getMessageList  (search, pageNum, pageSize) {
    return httpManager.post('/message/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize, search);
}
export function updateMessage  (message) {
    return httpManager.post('/message/update', message);
}
export function saveMessage  (message) {
    return httpManager.post('/message/save', message);
}
export function deleteMessage  (id) {
    return httpManager.get('/message/delete?id=' + id);
}
