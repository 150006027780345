import httpManager from "../axios/httpManager";

export function getXimaxBetlist(search, pageNum, pageSize) {
    let url = '/casino/bet_list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}

export function getXimaxAccountBalance(userId) {
    return httpManager.get('/casino/get_account_balance?userId=' + userId)
}

export function subjectXimaxMemberCasinoCash(userId) {
    return httpManager.get('/casino/subject_member_casino_cash?userId=' + userId)
}