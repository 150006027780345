import httpManager from "../axios/httpManager";

export function doLogin(manager) {
    return httpManager.post('/login', manager)
}

export function uploadFile(action, file) {
    let data = new FormData()
    data.append("file",file)
    return httpManager.post(action, data)
}

export function getLiveStatistic () {
    return httpManager.get('/statistic/topbar_statistic?t='+new Date().getTime());
}

export function getOnlineUser () {
    return httpManager.get('/get_online_users?t='+new Date().getTime());
}
export function getInfomation() {
    return httpManager.get('/topbar/get_infomation?t='+new Date().getTime());
}
export function  updateManagerPassword (manager) {
    return httpManager.post('/password/update', manager);
}
export function managerSignOut() {
    return httpManager.get('/signout')
}
