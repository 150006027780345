<template>

    <div style="padding: 5px 2px;">
        <table class="table100">
            <tr>
                <th>입금</th>
                <td>{{statistic.rechargeTotal|comma}} 원</td>
            </tr>
            <tr>
                <th>출금</th>
                <td>{{statistic.exchangeTotal|comma}} 원</td>
            </tr>
            <tr>
                <th>손이익</th>
                <td>{{(statistic.rechargeTotal - statistic.exchangeTotal)|comma}} 원</td>
            </tr>
            <tr>
                <th style="width: 50%">총회원</th>
                <td>{{statistic.siteMemberCount|comma}} 명</td>
            </tr>
            <tr>
                <th>신규회원</th>
                <td>{{statistic.newSiteMemberCount|comma}} 명 ({{statistic.realBeter}})</td>
            </tr>
            <tr>
                <th>보유머니</th>
                <td>{{statistic.ownCashTotal|comma}} 원</td>
            </tr>
            <tr>
                <th>보유포인트</th>
                <td>{{statistic.ownPointTotal|comma}} P</td>
            </tr>
            <tr>
                <th>일누적포인트</th>
                <td>{{statistic.accumulatePoints|comma}} P</td>
            </tr>
            <tr>
                <td colspan="2" style="height: 2px">&nbsp</td>
            </tr>
            <tr>
                <th>카지노 입금</th>
                <td>{{statistic.casinoRechargeTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>카지노 출금</th>
                <td>{{statistic.casinoExchangeTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>카지노 손이익</th>
                <td>{{(statistic.casinoRechargeTotal-statistic.casinoExchangeTotal)|comma}}  원</td>
            </tr>
            <tr>
                <td colspan="2" style="height: 2px">&nbsp</td>
            </tr>
            <tr>
                <th>Sports베팅회원</th>
                <td>{{statistic.betSportsUserCount|comma}}  명</td>
            </tr>
            <tr>
                <th>Sports총베팅수</th>
                <td>{{statistic.sportsBetCount|comma}}  개</td>
            </tr>
            <tr>
                <th>Sports베팅금액</th>
                <td>{{statistic.sportsBetCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Sports당첨금액</th>
                <td>{{statistic.sportsBetWinCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Sports취소금액</th>
                <td>{{statistic.sportsBetCancelCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Sports적특금액</th>
                <td>{{statistic.sportsBetSpecialCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Sports손이익</th>
                <td>{{statistic.sportsBetProfit|comma}}  원</td>
            </tr>
            <tr>
                <th>Sports베팅중개수</th>
                <td>{{statistic.ingSportsBetCount|comma}}  개</td>
            </tr>
            <tr>
                <th>Sports베팅중</th>
                <td>{{statistic.ingSportsBetCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Sports예상당첨</th>
                <td>{{statistic.ingSportsBetWillWinCashTotal|comma}}  원</td>
            </tr>

            <tr>
                <td colspan="2" style="height: 2px">&nbsp</td>
            </tr>
            <tr>
                <th>Inplay베팅회원</th>
                <td>{{statistic.betInplayUserCount|comma}}  명</td>
            </tr>
            <tr>
                <th>Inplay총베팅수</th>
                <td>{{statistic.inplayBetCount|comma}}  개</td>
            </tr>
            <tr>
                <th>Inplay베팅금액</th>
                <td>{{statistic.inplayBetCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Inplay당첨금액</th>
                <td>{{statistic.inplayBetWinCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Inplay취소금액</th>
                <td>{{statistic.inplayBetCancelCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Inplay적특금액</th>
                <td>{{statistic.inplayBetSpecialCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Inplay손이익</th>
                <td>{{statistic.inplayBetProfit|comma}}  원</td>
            </tr>
            <tr>
                <th>Inplay베팅중개수</th>
                <td>{{statistic.ingInplayBetCount|comma}}  개</td>
            </tr>
            <tr>
                <th>Inplay베팅중</th>
                <td>{{statistic.ingInplayBetCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Inplay예상당첨</th>
                <td>{{statistic.ingInplayBetWillWinCashTotal|comma}}  원</td>
            </tr>

            <tr>
                <td colspan="2" style="height: 2px">&nbsp</td>
            </tr>
            <tr>
                <th>Mini베팅회원</th>
                <td>{{statistic.betLeisureUserCount|comma}}  명</td>
            </tr>
            <tr>
                <th>Mini총베팅수</th>
                <td>{{statistic.leisureBetCount|comma}}  개</td>
            </tr>
            <tr>
                <th>Mini베팅금액</th>
                <td>{{statistic.leisureBetCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Mini당첨금액</th>
                <td>{{statistic.leisureBetWinCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Mini취소금액</th>
                <td>{{statistic.leisureBetCancelCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Mini적특금액</th>
                <td>{{statistic.leisureBetSpecialCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Mini손이익</th>
                <td>{{statistic.leisureBetProfit|comma}}  원</td>
            </tr>
            <tr>
                <th>Mini베팅중개수</th>
                <td>{{statistic.ingLeisureBetCount|comma}}  개</td>
            </tr>
            <tr>
                <th>Mini베팅중</th>
                <td>{{statistic.ingLeisureBetCashTotal|comma}}  원</td>
            </tr>
            <tr>
                <th>Mini예상당첨</th>
                <td>{{statistic.ingLeisureBetWillWinCashTotal|comma}}  원</td>
            </tr>



        </table>
    </div>


</template>

<script>


    import {getLiveStatistic} from "../../network/manager/commonRequest";

    export default {
        name: "TodayStatisticComp",
        data() {
            return {
                statistic: {},
            }
        },
        methods: {
            getLiveStatistic() {
                getLiveStatistic().then(res => {
                   this.statistic = res.data.data
                })
            },

        },
        created() {
            this.getLiveStatistic()
            setInterval(this.getLiveStatistic, 30000);
        },
        watch: {},
    }
</script>

<style scoped>
.table100 td{
    background-color: #cfcfd1!important;
}

</style>