<template>
    <div>
        <ul style="display: flex;justify-content: space-evenly;flex-wrap: wrap;list-style: none;width: 100%;padding: 0!important;margin: 0!important;">
            <li v-for="item in onlineUsers"
                @click="editUser(item.id,managerConst.MODE_EDIT)"
                style="width: 49%;text-align: center;cursor: pointer;padding: 2px 0;border-radius: 5px" class="bg-warning">
                <div style="cursor: pointer;font-size: 12px;">
                    <i class="fa fa-user"></i>{{item.nickname}}
                </div>
                <div style="font-size: 12px;">({{item.position}})</div>
                <div style="font-size: 12px;">({{item.logindomain}})</div>
                <div>
                    <span style="font-size: 11px;"  v-for="sameipuser in item.sameIpUsers">
                        <i class="fa fa-user-friends"></i>
                        {{sameipuser.nickname}}</span>
                </div>
            </li>
        </ul>
    </div>


</template>

<script>

    import Vue from "vue";
    import UserEditToast from "./UserPanel/UserEditToast";
    import {getOnlineUser} from "../../network/manager/commonRequest";
    import managerConst from "../../common/administrator/managerConst";

    Vue.prototype.$userEditToast = UserEditToast
    export default {
        name: "OnlineUserComp",
        data() {
            return {
                onlineUsers:[],
                managerConst:managerConst
            }
        },
        methods: {
            editUser(userId, mode) {
                this.$userEditToast({'userId': userId, 'mode': mode})
            },
            getOnlineUsers(){
                getOnlineUser().then(res=>{
                    this.onlineUsers = res.data.data
                })
            }

        },
        computed: {

        },
        created() {
            this.getOnlineUsers()
            setInterval(this.getOnlineUsers, 4000);
        },
        watch: {
        },
    }
</script>

<style scoped>


</style>