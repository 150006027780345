import httpManager from "../axios/httpManager";


export function findsiteCommonConfigOnly  () {
    return httpManager.get('/siteconfig/find_site_common_config?t='+new Date().getTime());
}
export function updateCommonConfig  (commonConfig) {
    return httpManager.post('/siteconfig/update_site_common_config', commonConfig);
}
export function getSiteConfigList  () {
    return httpManager.get('/siteconfig/site_list');
}
export function getSiteAndOwnConfigById  (id) {
    return httpManager.get('/siteconfig/site_ownconfig_by_id?id=' + id);
}
export function updateSiteAndOwnConfig (siteVo) {
    return httpManager.post('/siteconfig/update_site_ownconfig', siteVo);
}

export function saveSite  (site) {
    return httpManager.post('/siteconfig/save_site', site);
}

